export var NotificationType;
(function (NotificationType) {
    NotificationType["INFO"] = "info";
    NotificationType["ERROR"] = "error";
    NotificationType["SUCCESS"] = "success";
    NotificationType["WARNING"] = "warning";
})(NotificationType || (NotificationType = {}));
export function defaultNotification() {
    return {
        show: false,
        color: NotificationType.INFO,
        message: '',
        timeout: 5000,
        translate: true,
    };
}
export function initialState() {
    return {
        notifications: [],
    };
}
function notifyFactory(color) {
    return function notify({ dispatch }, message) {
        if (typeof message === 'object') {
            dispatch('show', { ...message, color });
            return;
        }
        dispatch('show', { message, color });
    };
}
const NotificationStore = {
    namespaced: true,
    state: initialState(),
    actions: {
        info: notifyFactory(NotificationType.INFO),
        error: notifyFactory(NotificationType.ERROR),
        success: notifyFactory(NotificationType.SUCCESS),
        warning: notifyFactory(NotificationType.WARNING),
        show({ commit }, options) {
            commit('discard');
            commit('show', Object.assign(defaultNotification(), options, { show: true }));
        },
    },
    mutations: {
        show(state, notification) {
            state.notifications.push(notification);
        },
        discard(state) {
            state.notifications = state.notifications.filter(n => n.show);
        },
        clear(state) {
            state.notifications = [];
        },
    },
};
export default NotificationStore;
