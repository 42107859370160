import { stringEnumValues } from '#/enum';
export var Domains;
(function (Domains) {
    Domains["PEDAGOGIC"] = "pedagogic";
    Domains["CHILDDEVELOPMENT"] = "child_development";
    Domains["APPROACHEMETHODOLOGIESANDPEDAGOGIC"] = "approach_methodologies_pedagogic";
    Domains["EMOTIONSOCIAL"] = "emotion_social";
    Domains["VALUEPARTICIPATIONDEMOCRACY"] = "value_participation_democracy";
    Domains["LANGUAGECOMMUNICATIONMEDIAS"] = "language_communication_media";
    Domains["AESTHETICCREATIVITYART"] = "aesthetic_creativity_art";
    Domains["MOVEMENTCONSCIOUSNESSHEALTH"] = "movement_consciousness_health";
    Domains["SCIENCETECHNOLOGY"] = "science_technology";
    Domains["TRANSITIONS"] = "transitions";
    Domains["MANAGERIALSKILLS"] = "management_regulatory_framework_managerial_skills";
    Domains["JURISDICTIONOVERLEGISLATION"] = "management_regulatory_framework_jurisdiction_over_legislation";
    Domains["OCCUPATIONALHEALTHSAFETY"] = "occupational_health_safety";
    Domains["COACHING"] = "coaching";
    Domains["SUPERVISION"] = "supervision";
})(Domains || (Domains = {}));
export const DomainAbbreviations = {
    [Domains.PEDAGOGIC]: 'OR',
    [Domains.CHILDDEVELOPMENT]: 'DE',
    [Domains.APPROACHEMETHODOLOGIESANDPEDAGOGIC]: 'AP',
    [Domains.EMOTIONSOCIAL]: 'EM',
    [Domains.VALUEPARTICIPATIONDEMOCRACY]: 'VA',
    [Domains.LANGUAGECOMMUNICATIONMEDIAS]: 'LA',
    [Domains.AESTHETICCREATIVITYART]: 'ES',
    [Domains.MOVEMENTCONSCIOUSNESSHEALTH]: 'MO',
    [Domains.SCIENCETECHNOLOGY]: 'SC',
    [Domains.TRANSITIONS]: 'TR',
    [Domains.MANAGERIALSKILLS]: 'MM',
    [Domains.JURISDICTIONOVERLEGISLATION]: 'ML',
    [Domains.OCCUPATIONALHEALTHSAFETY]: 'SE',
    [Domains.COACHING]: 'CO',
    [Domains.SUPERVISION]: 'SU',
};
export const domains = stringEnumValues(Domains);
