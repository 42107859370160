import { identity } from 'ramda';
export function translatedEnum(stringEnum, translate = identity) {
    return Object.entries(stringEnum)
        .filter(value => typeof value[1] === 'string')
        .map(value => ({ text: translate(value[0]), value: value[1] }));
}
export function numericEnumValues(anyEnum) {
    return Object.values(anyEnum).filter(value => typeof value !== 'string');
}
export function stringEnumValues(anyEnum) {
    return Object.values(anyEnum);
}
