import { Domains } from '#/domains';
import { Roles } from '#/role';
export default {
    Users: 'Users',
    User: 'User',
    'First name': 'First name',
    'Last name': 'First name',
    'E-mail': 'E-mail',
    Role: 'Role',
    'Go back': 'Go back',
    'Please confirm this action': 'Please confirm this action',
    'Are you sure you want to complete this action?': 'Are you sure you want to complete this action?',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    Delete: 'Delete',
    Save: 'Save',
    Search: 'Search',
    Statistics: 'Statistics',
    'N°': 'N°',
    'Delete user': 'Delete user',
    'Are you sure you want to delete this user?': 'Are you sure you want to delete this user?',
    'No data available': 'No data available',
    'Rows per page': 'Rows per page',
    tableFooter: '{pageStart} - {pageStop} of {itemsLength}',
    'Type to start searching': 'Type to start searching',
    'Searching…': 'Searching…',
    'No results found.': 'No results found.',
    'Date range': 'Date range',
    week_label: 'W',
    today: 'Today',
    yesterday: 'Yesterday',
    this_month: 'This month',
    last_month: 'Last month',
    this_year: 'This year',
    participation: {
        title: 'Participation|Participations',
        add: 'Add participation',
        list: 'List participations',
        present: 'N° present',
        excused: 'N° excused',
        absent: 'N° absent',
        deleteModalTitle: 'Delete participation',
        deleteModalQuestion: 'Are you sure you want to delete this participation?',
        focoParticipants: 'Foco participants',
        accproParticipants: 'AccPro participants',
        participants: 'Participants',
    },
    session: {
        title: 'Session|Sessions',
        date: 'Date',
    },
    crud: {
        saveSuccess: 'The data was successfully saved',
        deleteSuccess: 'The data was successfully deleted',
    },
    trainings: {
        title: 'Training|Trainings',
        name: 'Name',
        domain: 'Domain',
        description: 'Description',
        hours: 'Hours (Credits required)',
        type: 'Type',
        organizer: 'Organizer',
        creditType: 'Credit type',
        trainingType: 'Training type',
        reference: 'Training ID',
        successfullySaved: 'The training was successfully saved.',
        successfullyDeleted: 'The training was successfully deleted.',
        deleteModalTitle: 'Delete training',
        deleteModalQuestion: 'Are you sure you want to delete this training ?',
        idText: 'Autogenerated ID',
    },
    agencies: {
        title: 'Agency|Agencies',
        contactAccreditation: "Contact d'accréditation",
        contactTrainings: 'Contact formations',
        creditFocoConvention: 'Credits FoCo',
        creditAccConvention: 'Credits AccPro',
        deleteModalTitle: 'Delete agency',
        deleteModalQuestion: 'Are you sure you want to delete this agency ?',
        successfullySaved: 'The agency was successfully saved.',
        successfullyDeleted: 'The agency was successfully deleted.',
    },
    services: {
        title: 'Service|Services',
        approvalId: 'Approval ID'
    },
    Number: 'Number',
    Street: 'Street',
    Zip: 'Zip',
    City: 'City',
    Type: 'Type',
    Year: 'Year',
    'Initial Credit': 'Initial Credit',
    'Modified Credit': 'Modified Credit',
    Reference: 'Reference',
    Name: 'Name',
    Notes: 'Notes',
    'Delete service': 'Delete service',
    'Are you sure you want to delete this service?': 'Are you sure you want to delete this service?',
    ID: 'ID',
    'Remaining Credit': 'Remaining Credit',
    'Used Credit': 'Used Credit',
    'Credit total/used/remain': 'Credit total/used/remain',
    'You have unsaved data on this page': 'You have unsaved data on this page',
    'There is unsaved data on this page. Do you want to return to the page to save the data, or do you want to continue without saving?': 'There is unsaved data on this page. Do you want to return to the page to save the data, or do you want to continue without saving?',
    'Continue without saving': 'Continue without saving',
    'Cancel and return to form': 'Cancel and return to form',
    'This document already exists': 'This document already exists',
    'The requested document was not found': 'The requested document was not found',
    'You are not allowed to perform this action': 'You are not allowed to perform this action',
    'Bad request': 'Bad request',
    'Internal server error': 'Internal server error',
    'The server is not responding, please try again later': 'The server is not responding, please try again later',
    'The service was successfully saved': 'The service was successfully saved',
    'The service was successfully deleted': 'The service was successfully deleted',
    'The trainer was successfully saved': 'The trainer was successfully saved',
    'The trainer was successfully deleted': 'The trainer was successfully deleted',
    'The user was successfully saved': 'The user was successfully saved',
    'The user was successfully deleted': 'The user was successfully deleted',
    General: 'General',
    Profile: 'Profile',
    Organization: 'Organization',
    Contact: 'Contact in charge of management',
    Address: 'Address',
    Credits: 'Credits',
    creditType: {
        foco: 'FoCo',
        accpro: 'AccPro',
    },
    validations: {
        required: 'The field is required',
        email: 'The e-mail address is not valid',
    },
    Certificate: 'Certificate',
    'Add certificate': 'Add certificate',
    'Remove certificate': 'Remove certificate',
    'Certificate Type': 'Certificate Type',
    Specification: 'Specification',
    'Activity Type': 'Activity Type',
    'Processing Date': 'Processing Date',
    OK: 'OK',
    trainer: {
        title: 'Trainer|Trainers',
    },
    Status: 'Status',
    Employer: 'Employer',
    'Is State Employee': 'Is State Employee',
    Languages: 'Languages',
    Diplomas: 'Diplomas',
    Certificates: 'Certificates',
    'n/a': 'n/a',
    'Delete trainer': 'Delete trainer',
    'Are you sure you want to delete this trainer?': 'Are you sure you want to delete this trainer?',
    Budget: 'Budget',
    roles: {
        [Roles.SNJ]: 'SNJ',
        [Roles.AGENCY]: 'Agency',
        [Roles.MANAGER]: 'Manager',
        [Roles.SERVICE]: 'Service',
    },
    domains: {
        [Domains.PEDAGOGIC]: 'Pedagogical orientation',
        [Domains.CHILDDEVELOPMENT]: 'Child and youth development',
        [Domains.APPROACHEMETHODOLOGIESANDPEDAGOGIC]: 'Educational approaches and methodologies',
        [Domains.EMOTIONSOCIAL]: 'Emotions and social relationships',
        [Domains.VALUEPARTICIPATIONDEMOCRACY]: 'Values, participation and democracy',
        [Domains.LANGUAGECOMMUNICATIONMEDIAS]: 'Language, communication and media',
        [Domains.AESTHETICCREATIVITYART]: 'Aesthetics, creativity and art',
        [Domains.MOVEMENTCONSCIOUSNESSHEALTH]: 'Movement, Body awareness and health',
        [Domains.SCIENCETECHNOLOGY]: 'Science and technology',
        [Domains.TRANSITIONS]: 'Transitions',
        [Domains.MANAGERIALSKILLS]: 'Management and regulatory framework - Managerial skills',
        [Domains.JURISDICTIONOVERLEGISLATION]: 'Management and regulatory framework - Competences in the field of legislation',
        [Domains.OCCUPATIONALHEALTHSAFETY]: 'Sécurité et santé au travail',
        [Domains.COACHING]: 'Coaching',
        [Domains.SUPERVISION]: 'Supervision',
    },
    LuxTrustSN20: 'Serial number of the LuxTrust certificate',
    userId: 'CGIE IAM username',
    Logout: 'Logout',
    Export: 'Export',
};
