import { getId } from 'shared/src/object-id';
export function amendAll(state, { items, totalCount }) {
    state.all = state.all.concat(items);
    state.totalCount = state.all.length;
}
export function refreshAll(state, { items, totalCount }) {
    state.all = items;
    state.totalCount = totalCount;
}
export function refreshEdit(state, item) {
    state.editing = item;
}
export function cancelEdit(state) {
    state.editing = undefined;
}
export function removeOne(state, toDelete) {
    const toDeleteId = getId(toDelete);
    state.all.splice(state.all.findIndex(item => getId(item) === toDeleteId), 1);
}
