import Vue from 'vue';
import axios from '~/backend';
import store from '~/store';
import Session from '~/authorization/session';
import VueRouter from 'vue-router';
import { identity, pathOr, always, cond, T, mergeRight } from 'ramda';
import { getUserOrganizationOverride } from '#/organization';
Vue.use(VueRouter);
export const routeNames = {
    services: {
        list: 'services-list',
        edit: 'services-form',
        create: 'services-form-create',
    },
    participations: {
        list: 'participations-list',
        edit: 'participations-form',
        create: 'participations-form-create',
    },
    trainers: {
        list: 'trainers-list',
        edit: 'trainers-form',
        create: 'trainers-form-create',
    },
    users: {
        list: 'users-list',
        edit: 'users-form',
        create: 'users-form-create',
    },
    trainings: {
        list: 'trainings-list',
        edit: 'trainings-form',
        create: 'trainings-form-create',
    },
    agencies: {
        list: 'agencies-list',
        edit: 'agencies-form',
        create: 'agencies-form-create',
    },
};
const userOrganization = () => store.getters['authorization/userOrganization'];
const userOrganizationType = () => store.getters['authorization/userOrganizationType'];
const canListUsers = () => store.getters['authorization/authorizedTo']('listUsers');
const canReadUsers = () => store.getters['authorization/authorizedTo']('readUsers');
const canCreateUsers = () => store.getters['authorization/authorizedTo']('createUsers');
const canReadSelf = (id) => store.getters['authorization/authorizedTo']('readOwnUser', id);
const canListServices = () => store.getters['authorization/authorizedTo']('listServices');
const canReadServices = () => store.getters['authorization/authorizedTo']('readServices');
const canCreateServices = () => store.getters['authorization/authorizedTo']('createServices');
const canReadOwnService = (id) => store.getters['authorization/authorizedTo']('readOwnService', id);
const canListAgencies = () => store.getters['authorization/authorizedTo']('listAgencies');
const canReadAgencies = () => store.getters['authorization/authorizedTo']('readAgencies');
const canCreateAgencies = () => store.getters['authorization/authorizedTo']('createAgencies');
const canReadOwnAgency = (id) => store.getters['authorization/authorizedTo']('readOwnAgency', id);
const canListTrainings = () => store.getters['authorization/authorizedTo']('listTrainings');
const canReadTrainings = () => store.getters['authorization/authorizedTo']('readTrainings');
const canCreateTrainings = () => store.getters['authorization/authorizedTo']('createTrainings');
const canListTrainers = () => store.getters['authorization/authorizedTo']('listTrainers');
const canReadTrainers = () => store.getters['authorization/authorizedTo']('readTrainers');
const canCreateTrainers = () => store.getters['authorization/authorizedTo']('createTrainers');
const canListParticipations = () => store.getters['authorization/authorizedTo']('listParticipations');
const canListOwnParticipations = () => store.getters['authorization/authorizedTo']('listOwnParticipations');
const canReadParticipations = () => store.getters['authorization/authorizedTo']('readParticipations');
const canCreateParticipations = () => store.getters['authorization/authorizedTo']('createParticipations');
const routes = [
    {
        path: '/',
        redirect: cond([
            [canListServices, always('/services')],
            [canListAgencies, always('/agencies')],
            [canListParticipations, always('/participations')],
            [canListOwnParticipations, always('/participations')],
            [T, always('/not-found')],
        ]),
    },
    {
        path: '/services',
        component: () => import('~/services/service-list.vue'),
        name: routeNames.services.list,
        meta: {
            navigationAllowed: canListServices
        },
    },
    {
        path: '/services/edit/:id',
        component: () => import('~/services/service-form.vue'),
        name: routeNames.services.edit,
        props: true,
        meta: {
            navigationAllowed(to) {
                var _a;
                return canReadServices() || canReadOwnService((_a = to === null || to === void 0 ? void 0 : to.params) === null || _a === void 0 ? void 0 : _a.id);
            }
        },
    },
    {
        path: '/services/new',
        component: () => import('~/services/service-form.vue'),
        name: routeNames.services.create,
        meta: {
            navigationAllowed: canCreateServices
        },
    },
    {
        path: '/participations',
        component: () => import('~/participations/participation-list.vue'),
        name: routeNames.participations.list,
        props: route => {
            let queryOverride = {};
            if (canListOwnParticipations() && !canListParticipations() && userOrganization() && userOrganizationType()) {
                queryOverride = getUserOrganizationOverride(userOrganizationType(), userOrganization());
            }
            return mergeRight({
                service: route.query.service,
                training: route.query.training,
                agency: route.query.agency,
            }, queryOverride);
        },
        meta: {
            navigationAllowed() {
                return canListOwnParticipations() || canListParticipations();
            }
        },
    },
    {
        path: '/participations/edit/:id',
        component: () => import('~/participations/participation-form.vue'),
        name: routeNames.participations.edit,
        props: true,
        meta: {
            navigationAllowed: canReadParticipations
        },
    },
    {
        path: '/participations/new',
        component: () => import('~/participations/participation-form.vue'),
        name: routeNames.participations.create,
        props: route => ({
            service: route.query.service,
            training: route.query.training
        }),
        meta: {
            navigationAllowed: canCreateParticipations
        },
    },
    {
        path: '/trainers',
        component: () => import('~/trainers/trainer-list.vue'),
        name: routeNames.trainers.list,
        meta: {
            navigationAllowed: canListTrainers
        },
    },
    {
        path: '/trainers/edit/:id',
        component: () => import('~/trainers/trainer-form.vue'),
        name: routeNames.trainers.edit,
        props: true,
        meta: {
            navigationAllowed: canReadTrainers
        },
    },
    {
        path: '/trainers/new',
        component: () => import('~/trainers/trainer-form.vue'),
        name: routeNames.trainers.create,
        meta: {
            navigationAllowed: canCreateTrainers
        },
    },
    {
        path: '/users',
        component: () => import('~/users/user-list.vue'),
        name: routeNames.users.list,
        meta: {
            navigationAllowed: canListUsers
        },
    },
    {
        path: '/users/edit/:id',
        component: () => import('~/users/user-form.vue'),
        name: routeNames.users.edit,
        props: true,
        meta: {
            navigationAllowed(to) {
                var _a;
                return canReadUsers() || canReadSelf((_a = to === null || to === void 0 ? void 0 : to.params) === null || _a === void 0 ? void 0 : _a.id);
            }
        },
    },
    {
        path: '/users/new',
        component: () => import('~/users/user-form.vue'),
        name: routeNames.users.create,
        meta: {
            navigationAllowed: canCreateUsers
        },
    },
    {
        path: '/trainings',
        component: () => import('~/trainings/trainings-list.vue'),
        name: routeNames.trainings.list,
        meta: {
            navigationAllowed: canListTrainings
        },
    },
    {
        path: '/trainings/edit/:id',
        component: () => import('~/trainings/trainings-form.vue'),
        name: routeNames.trainings.edit,
        props: true,
        meta: {
            navigationAllowed: canReadTrainings
        },
    },
    {
        path: '/trainings/new',
        component: () => import('~/trainings/trainings-form.vue'),
        name: routeNames.trainings.create,
        meta: {
            navigationAllowed: canCreateTrainings
        },
    },
    {
        path: '/agencies',
        component: () => import('~/agencies/agency-list.vue'),
        name: routeNames.agencies.list,
        meta: {
            navigationAllowed: canListAgencies
        },
    },
    {
        path: '/agencies/edit/:id',
        component: () => import('~/agencies/agency-form.vue'),
        name: routeNames.agencies.edit,
        props: true,
        meta: {
            navigationAllowed(to) {
                var _a;
                return canReadAgencies() || canReadOwnAgency((_a = to === null || to === void 0 ? void 0 : to.params) === null || _a === void 0 ? void 0 : _a.id);
            }
        },
    },
    {
        path: '/agencies/new',
        component: () => import('~/agencies/agency-form.vue'),
        name: routeNames.agencies.create,
        meta: {
            navigationAllowed: canCreateAgencies
        },
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
Session.on('session-closed', async () => {
    return redirectToLoginPage();
});
async function redirectToLoginPage() {
    const loginPageUrl = await store.dispatch('authorization/loginPageUrl');
    if (loginPageUrl) {
        window.location.href = loginPageUrl;
    }
    else {
    }
}
router.beforeEach(async function beforeEachRoute(to, from, next) {
    console.log(`%c[router:before] %ctrying to navigate to ${to.path}`, 'color: turquoise;', 'color: gray;');
    try {
        await store.dispatch('authorization/refresh');
    }
    catch (_) {
    }
    if (Session.authenticated() && navigationIsAllowed(to)) {
        return next();
    }
    return redirectToLoginPage();
});
axios.interceptors.response.use(identity, error => {
    var _a;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && Session.authenticated()) {
        Session.logout();
    }
    else {
        throw error;
    }
});
function navigationIsAllowed(to) {
    return to.matched.every(route => {
        const navigationGuardFunction = pathOr(always(true), ['meta', 'navigationAllowed'], route);
        return navigationGuardFunction(to);
    });
}
export default router;
