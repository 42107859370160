import Vue from 'vue';
import Vuetify from 'vuetify/lib';
Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                primary: '#00AEEF',
                secondary: '#1D71B8',
                info: '#B0D5F2',
                warning: '#ED6F3A',
                error: '#ED1C24',
            },
            dark: {
                primary: '#00AEEF',
                secondary: '#1D71B8',
                info: '#B0D5F2',
                warning: '#ED6F3A',
                error: '#ED1C24',
            },
        },
    },
});
