import upperFirst from 'lodash.upperfirst';
export function createUser(values = {}) {
    return Object.assign({}, {
        email: null,
        firstName: null,
        lastName: null,
        role: null,
    }, values);
}
export function displayName(user) {
    return ((user === null || user === void 0 ? void 0 : user.lastName) && (user === null || user === void 0 ? void 0 : user.firstName)
        ? `${user.lastName.toUpperCase()} ${upperFirst(user.firstName)}`
        : '');
}
