import { enUS as en, fr } from 'date-fns/locale';
export const dateLocales = { en, fr };
let defaultLocale = 'fr';
export function setDefaultLocale(locale) {
    if (locale) {
        defaultLocale = locale;
    }
}
export function getDefaultLocale() {
    return defaultLocale;
}
