import { Optional } from 'lonad';
import { any, all } from 'ramda';
import allPermissions from '#/permissions/all-permissions';
const allowed = Symbol('allowed');
const denied = Symbol('denied');
export default class Authorization {
    constructor({ user, permissions }) {
        this.user = user;
        this.permissions = permissions;
    }
    static always(always) {
        const authorization = new Authorization({
            user: Optional.None(),
            permissions: new Map(),
        });
        authorization.force = always ? allowed : denied;
        return authorization;
    }
    static for(user, permissions) {
        return new Authorization({
            user,
            permissions: permissions.reduce((map, permission) => {
                if (allPermissions[permission]) {
                    map.set(permission, allPermissions[permission]);
                }
                return map;
            }, new Map()),
        });
    }
    is(role) {
        return this.user
            .map(user => user.role === role)
            .getOrElse(false);
    }
    has(permission) {
        if ([allowed, denied].includes(this.force)) {
            return this.force === allowed;
        }
        return this.user
            .map(user => {
            return this === null || this === void 0 ? void 0 : this.permissions.has(permission);
        })
            .getOrElse(false);
    }
    can(permission, target) {
        if ([allowed, denied].includes(this.force)) {
            return this.force === allowed;
        }
        return this.user
            .map(user => {
            return (this === null || this === void 0 ? void 0 : this.permissions.has(permission)) && this.permissions.get(permission).test(user, target);
        })
            .getOrElse(false);
    }
    any(permissions, target) {
        return any(permission => this.can(permission, target), permissions);
    }
    all(permissions, target) {
        return all(permission => this.can(permission, target), permissions);
    }
}
