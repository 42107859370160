import NotificationSnackbar from '~/components/notification-snackbar.vue';
import ResponsiveNavbar from '~/components/responsive-navbar.vue';
export default {
    name: 'app',
    components: {
        NotificationSnackbar,
        ResponsiveNavbar,
    },
    data: () => ({
        version: PUBLIC_VERSION,
        fullVersion: VERSION,
    }),
};
