import GlobalConfirmDialog from '~/components/global-confirm-dialog.vue';
import Vue from 'vue';
const EventBus = new Vue();
const GlobalConfirmDialogPlugin = {
    EventBus,
    install(VueInstance, options) {
        VueInstance.component('global-confirm-dialog', GlobalConfirmDialog);
        VueInstance.prototype.$globalConfirmDialog = {
            open(props) {
                return new Promise((resolve, reject) => GlobalConfirmDialogPlugin.EventBus.$emit('open', props, resolve, reject));
            },
        };
    },
};
export default GlobalConfirmDialogPlugin;
