import { stringEnumValues } from '#/enum';
export var CreditType;
(function (CreditType) {
    CreditType["FOCO"] = "foco";
    CreditType["ACCPRO"] = "accpro";
})(CreditType || (CreditType = {}));
export const creditTypes = stringEnumValues(CreditType);
export const CreditYears = [
    2022,
    2023,
];
export function uniqueKey(credit) {
    return `${credit.year}_${credit.type}`;
}
export function createAllCredits() {
    const credits = [];
    CreditYears.forEach(year => {
        Object.values(CreditType).forEach(type => {
            credits.push(createCredit({
                year,
                type,
            }));
        });
    });
    return credits;
}
export function createCredit(values = {}) {
    return Object.assign({}, {
        type: CreditType.FOCO,
        year: (new Date()).getFullYear(),
        initial: 0,
        modifier: 0,
    }, values);
}
export function evaluateTotalCredit(credit) {
    return credit.initial + credit.modifier;
}
