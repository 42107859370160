import λ from '~/utils/invoker';
import { routeNames } from '~/router';
import { CreditYears } from '#/credit';
import PermissionMixin from '~/mixins/permission';
import { currentUserRole, currentUserId, currentUserName, currentUser } from '~/store/callcised';
import { mdiMenu, mdiChevronDown, mdiCalendar, mdiCog, mdiAccountGroup, mdiLogoutVariant, mdiAccountCircle, } from '@mdi/js';
const authorizationλ = λ.namespaced('authorization');
export default {
    name: 'responsive-navbar',
    mixins: [PermissionMixin],
    data: () => ({
        mdiMenu,
        mdiCalendar,
        mdiChevronDown,
        mdiAccountGroup,
        mdiLogoutVariant,
        mdiAccountCircle,
        mdiCog,
        appTitle: 'SNJ - Formation Continue',
    }),
    computed: {
        currentUser,
        currentUserId,
        currentUserRole,
        currentUserName,
        selectedYear: λ.vuexState('year'),
        mayReadOwnService() {
            return this.mayBeAuthorizedTo('readOwnService');
        },
        mayReadOwnAgency() {
            return this.mayBeAuthorizedTo('readOwnAgency');
        },
        availableYears() {
            return CreditYears.filter(year => year !== this.selectedYear);
        },
        pages() {
            return [
                ...(this.canListServices ? [{ title: this.$tc('services.title', 2), route: '/services' }] : []),
                ...(this.canListTrainings ? [{ title: this.$tc('trainings.title', 2), route: '/trainings' }] : []),
                ...(this.canListParticipations || this.canListOwnParticipations ? [{ title: this.$tc('participation.title', 2), route: '/participations' }] : []),
                ...(this.canListTrainers ? [{ title: this.$tc('trainer.title', 2), route: '/trainers' }] : []),
                ...(this.canListAgencies ? [{ title: this.$tc('agencies.title', 2), route: '/agencies' }] : []),
                ...(!this.canListAgencies && this.mayReadOwnAgency ? [{ title: this.$tc('agencies.title', 1), action: this.goToOwnOrganizer }] : []),
                ...(!this.canListServices && this.mayReadOwnService ? [{ title: this.$tc('services.title', 1), action: this.goToOwnOrganizer }] : []),
            ];
        },
        userMenuOptions() {
            return [
                { icon: mdiAccountCircle, title: this.$t('Profile'), action: this.goToProfile },
                ...(this.canListUsers ? [{ icon: mdiAccountGroup, title: this.$t('Users'), route: '/users' }] : []),
                { icon: mdiLogoutVariant, title: this.$t('Logout'), action: this.logout },
            ];
        }
    },
    methods: {
        onClick(action) {
            if (action && typeof action === 'function') {
                action();
            }
        },
        changeYear: λ.vuexMutation('changeYear'),
        logout: authorizationλ.action('logout'),
        goToProfile() {
            this.currentUserId.tap(id => {
                this.$router.push({ name: routeNames.users.edit, params: { id } }).catch(e => e);
            });
        },
        goToOwnOrganizer() {
            this.currentUser.tap(user => {
                this.$router.push({
                    name: routeNames[user.organizationType].edit,
                    params: { id: user.organization },
                }).catch(e => e);
            });
        },
    },
};
