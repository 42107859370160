import Vue from 'vue';
import Vuex from 'vuex';
import users from '~/users/user-store';
import services from '~/services/service-store';
import trainers from '~/trainers/trainer-store';
import agencies from '~/agencies/agency-store';
import trainings from '~/trainings/trainings-store';
import notifications from '~/store/notifications-store';
import authorization from '~/authorization/authorization-store';
import participations from '~/participations/participation-store';
import { CreditYears } from '#/credit';
import { getYear } from 'date-fns';
import { max, min, head, last } from 'ramda';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        year: min(max(getYear(new Date()), head(CreditYears)), last(CreditYears)),
    },
    actions: {},
    mutations: {
        changeYear(state, year) {
            state.year = year;
        },
    },
    getters: {},
    modules: {
        users,
        services,
        trainers,
        agencies,
        trainings,
        notifications,
        authorization,
        participations,
    },
});
