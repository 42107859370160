import { getId } from '#/object-id';
import Permission from '#/permissions/permission';
import { OrganizationTypes } from '#/organization';
import { zipObj } from 'ramda';
export function isSelf({ user: requestUser, target: targetUser }) {
    return getId(targetUser) === getId(requestUser);
}
;
export function isOwnService({ user, target }) {
    return user.organization
        && user.organizationType === OrganizationTypes.SERVICE
        && getId(user.organization) === getId(target);
}
;
export function isOwnAgency({ user, target }) {
    return user.organization
        && user.organizationType === OrganizationTypes.AGENCY
        && getId(user.organization) === getId(target);
}
;
export const participationPermissions = [
    Permission.allow('listParticipations'),
    Permission.allow('listOwnParticipations'),
    Permission.allow('createParticipations'),
    Permission.allow('readParticipations'),
    Permission.allow('updateParticipations'),
    Permission.allow('deleteParticipations'),
    Permission.allow('exportParticipations'),
];
export const trainingPermissions = [
    Permission.allow('listTrainings'),
    Permission.allow('createTrainings'),
    Permission.allow('readTrainings'),
    Permission.allow('updateTrainings'),
    Permission.allow('deleteTrainings'),
    Permission.allow('exportTrainings'),
];
export const trainersPermissions = [
    Permission.allow('listTrainers'),
    Permission.allow('createTrainers'),
    Permission.allow('readTrainers'),
    Permission.allow('updateTrainers'),
    Permission.allow('deleteTrainers'),
    Permission.allow('exportTrainers'),
];
export const agencyPermissions = [
    Permission.allow('listAgencies'),
    Permission.allow('createAgencies'),
    Permission.allow('readOwnAgency').when(isOwnAgency),
    Permission.allow('readAgencies'),
    Permission.allow('updateOwnAgency').when(isOwnAgency),
    Permission.allow('updateAgencies'),
    Permission.allow('deleteOwnAgency').when(isOwnAgency),
    Permission.allow('deleteAgencies'),
    Permission.allow('exportAgencies'),
];
export const servicePermissions = [
    Permission.allow('listServices'),
    Permission.allow('createServices'),
    Permission.allow('readOwnService').when(isOwnService),
    Permission.allow('readServices'),
    Permission.allow('updateOwnService').when(isOwnService),
    Permission.allow('updateServices'),
    Permission.allow('deleteOwnService').when(isOwnService),
    Permission.allow('deleteServices'),
    Permission.allow('exportServices'),
];
export const userPermissions = [
    Permission.allow('listUsers'),
    Permission.allow('createUsers'),
    Permission.allow('readUsers'),
    Permission.allow('updateUsers'),
    Permission.allow('deleteUsers'),
    Permission.allow('changeUserRole'),
    Permission.allow('changeUserOrganization'),
    Permission.allow('readOwnUser').when(isSelf),
    Permission.allow('updateOwnUser').when(isSelf),
];
const all = [
    ...participationPermissions,
    ...trainingPermissions,
    ...trainersPermissions,
    ...agencyPermissions,
    ...servicePermissions,
    ...userPermissions,
];
const permissions = zipObj(all, all);
export default permissions;
