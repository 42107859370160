import { getId } from '#/object-id';
import { createAgency } from '#/agency';
import { refreshAll, amendAll, refreshEdit, cancelEdit, removeOne } from '~/store/mutations';
import { downloadExport } from '~/store/actions';
import { allById } from '~/store/getters';
import { crudParachute, fetchAll, fetchOne, save, remove } from '~/utils/crud-functions';
const ServiceStore = {
    namespaced: true,
    state: initialState(),
    actions: {
        downloadExport,
        fetchAll: crudParachute(async ({ commit, state }, parameters) => {
            const results = await fetchAll(state.name, parameters);
            commit('refreshAll', results);
        }),
        amendAll: crudParachute(async ({ commit, state }, parameters) => {
            const results = await fetchAll(state.name, parameters);
            commit('amendAll', results);
        }),
        fetchEdit: crudParachute(async ({ commit, state }, objectOrId) => {
            const agency = await fetchOne(state.name, objectOrId);
            commit('refreshEdit', agency);
        }),
        saveEdit: crudParachute(async ({ commit, state, dispatch }) => {
            const agency = await save(state.name, state.editing);
            commit('refreshEdit', agency);
            dispatch('notifications/show', { message: 'agencies.successfullySaved', color: 'success' }, { root: true });
            return agency;
        }),
        remove: crudParachute(async ({ commit, state, dispatch }, agency) => {
            await remove(state.name, getId(agency));
            commit('removeOne', agency);
            dispatch('notifications/show', { message: 'agencies.successfullyDeleted', color: 'success' }, { root: true });
        }),
        createNew() {
            return createAgency();
        },
    },
    mutations: {
        refreshAll,
        refreshEdit,
        cancelEdit,
        removeOne,
        amendAll,
    },
    getters: {
        allById,
    },
};
export default ServiceStore;
export function initialState() {
    return {
        name: 'agencies',
        all: [],
        editing: undefined,
        totalCount: 0,
    };
}
