import parachute from '~/utils/parachute';
import { getId } from 'shared/src/object-id';
import axios from '~/backend';
import HttpStatus from 'http-status-codes';
import store from '~/store';
export function crudParachute(λ) {
    return parachute(runCrudAction);
    async function runCrudAction(context, ...otherParameters) {
        var _a;
        try {
            return await λ(...[context, ...otherParameters]);
        }
        catch (error) {
            switch ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) {
                case HttpStatus.CONFLICT:
                    store.dispatch('notifications/error', 'This document already exists', { root: true });
                    break;
                case HttpStatus.NOT_FOUND:
                    store.dispatch('notifications/error', 'The requested document was not found', { root: true });
                    break;
                case HttpStatus.FORBIDDEN:
                    store.dispatch('notifications/error', 'You are not allowed to perform this action', { root: true });
                    break;
                case HttpStatus.BAD_REQUEST:
                    store.dispatch('notifications/error', 'Bad request', { root: true });
                    break;
                case HttpStatus.INTERNAL_SERVER_ERROR:
                    store.dispatch('notifications/error', 'Internal server error', { root: true });
                    break;
                default:
                    store.dispatch('notifications/error', 'The server is not responding, please try again later', { root: true });
                    throw error;
            }
        }
    }
}
export const fetchOne = async (resource, item) => {
    return (await axios.get(`${resource}/${getId(item)}`)).data;
};
export const fetchAll = async (resource, params = {}) => {
    return (await axios.get(resource, { params })).data;
};
export const save = async (resource, item) => {
    return (await axios.put(resource, item)).data;
};
export const remove = async (resource, item) => {
    return (await axios.delete(`${resource}/${getId(item)}`)).data;
};
export function serviceFactory(resource) {
    const service = {};
    const functions = {
        fetchOne,
        fetchAll,
        save,
        remove,
    };
    for (const crudFunction in functions) {
        service[crudFunction] = function applyCrudFunction(...parameters) {
            return functions[crudFunction].apply(this, [resource, ...parameters]);
        };
    }
    return service;
}
