import { mdiClose } from '@mdi/js';
export default {
    data() {
        return {
            mdiClose,
            dialog: false,
            resolve: null,
            reject: null,
        };
    },
    props: {
        title: {
            type: String,
            default() { return this.$t('Please confirm this action'); },
        },
        text: {
            type: String,
            default() { return this.$t('Are you sure you want to complete this action?'); },
        },
        confirmLabel: {
            type: String,
            default() { return this.$t('Confirm'); },
        },
        confirmColor: {
            type: String,
            default: 'error',
        },
        cancelLabel: {
            type: String,
            default() { return this.$t('Cancel'); },
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        maxWidth: {
            default: '512px',
        },
        width: {
            default: '80%',
        },
        disabled: {
            default: false,
        },
    },
    watch: {
        dialog(dialog) {
            if (dialog) {
                this.$emit('dialog:open');
            }
            else {
                this.$emit('dialog:close');
            }
        },
    },
    methods: {
        open() {
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        confirm() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
        close() {
            this.reject();
            this.dialog = false;
        },
    },
};
