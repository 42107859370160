import { Domains } from '#/domains';
import { Roles } from '#/role';
export default {
    Users: 'Utilisateurs',
    User: 'Utilisateur',
    'First name': 'Prénom',
    'Last name': 'Nom de famille',
    'E-mail': 'Courriel',
    Role: 'Rôle',
    'Go back': 'Retour',
    'Please confirm this action': 'Veuillez confirmer cette action',
    'Are you sure you want to complete this action?': 'Êtes-vous sûr·e de vouloir terminer cette action ?',
    Cancel: 'Annuler',
    Confirm: 'Confirmer',
    Delete: 'Supprimer',
    Save: 'Enregistrer',
    Search: 'Rechercher',
    Statistics: 'Statistiques',
    'N°': 'N°',
    'Delete user': "Supprimer l'utilisateur",
    'Are you sure you want to delete this user?': 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    'No data available': 'Pas de données disponibles',
    'Rows per page': 'Lignes par page',
    tableFooter: '{pageStart} - {pageStop} de {itemsLength}',
    'Type to start searching': 'Tapez pour lancer la recherche',
    'Searching…': 'Recherche...',
    'No results found.': 'Aucun résultat trouvé.',
    'Date range': 'Plage de dates',
    week_label: 'S',
    today: "Aujourd'hui",
    yesterday: 'Hier',
    this_month: 'Ce mois',
    last_month: 'Le mois dernier',
    this_year: 'Cette année',
    participation: {
        title: 'Participation|Participations',
        add: 'Ajouter une participation',
        list: 'Voir participations',
        present: 'N° présent',
        excused: 'N° excusés',
        absent: 'N° non-excusés',
        deleteModalTitle: 'Supprimer la participation',
        deleteModalQuestion: 'Êtes-vous sûr de vouloir supprimer cette participation ?',
        focoParticipants: 'Foco participants',
        accproParticipants: 'AccPro participants',
        participants: 'Participants',
    },
    session: {
        title: 'Session|Sessions',
        date: 'Date',
    },
    crud: {
        saveSuccess: 'Les données ont été sauvegardées avec succès',
        deleteSuccess: 'Les données ont été supprimées avec succès',
    },
    trainings: {
        title: 'Formation|Formations',
        name: 'Dénomination formation',
        domain: 'Domaine',
        description: 'Description',
        hours: 'Les heures (Credits required)',
        type: 'Type',
        organizer: 'Organisateur',
        creditType: 'Type de crédit',
        trainingType: 'Type de formation',
        reference: 'ID Formation',
        successfullySaved: 'La formation a été enregistrée avec succès.',
        successfullyDeleted: 'La formation a été supprimée avec succès.',
        deleteModalTitle: 'Supprimer la formation',
        deleteModalQuestion: 'Êtes-vous sûr de vouloir supprimer cette formation ?',
        idText: 'Identifiant généré automatiquement',
    },
    agencies: {
        title: 'Agence|Agences',
        contactAccreditation: "Contact d'accréditation",
        contactTrainings: 'Contact formations',
        creditFocoConvention: 'Crédits FoCo',
        creditAccConvention: 'Crédits AccPro',
        deleteModalTitle: "Supprimer l'agence",
        deleteModalQuestion: 'Êtes-vous sûr de vouloir supprimer cette agence ?',
        successfullySaved: "L'agence a été enregistrée avec succès.",
        successfullyDeleted: "L'agence a été supprimée avec succès.",
    },
    services: {
        title: 'Service|Services',
        approvalId: "Numéro d'agrément"
    },
    Number: 'Numéro',
    Street: 'Rue',
    Zip: 'Code postal',
    City: 'Ville',
    Type: 'Type',
    Year: 'Année',
    'Initial Credit': 'Crédit initial',
    'Modified Credit': 'Crédit modifié',
    Reference: 'Référence',
    Name: 'Intitulé',
    Notes: 'Notes',
    'Delete service': 'Supprimer le service',
    'Are you sure you want to delete this service?': 'Êtes-vous sûr de vouloir supprimer ce service ?',
    ID: 'ID',
    'Remaining Credit': 'Crédit restant',
    'Used Credit': 'Crédit consommé',
    'Credit total/used/remain': 'Credit total/cons./restant',
    'You have unsaved data on this page': 'Vous avez des données non sauvegardées sur cette page',
    'There is unsaved data on this page. Do you want to return to the page to save the data, or do you want to continue without saving?': 'Il y a des données non sauvegardées sur cette page. Voulez-vous retourner à la page pour sauvegarder les données, ou voulez-vous continuer sans sauvegarder ?',
    'Continue without saving': 'Continuer sans sauvegarder',
    'Cancel and return to form': 'Annuler et retourner au formulaire',
    'This document already exists': 'Ce document existe déjà',
    'The requested document was not found': "Le document demandé n'a pas été trouvé",
    'You are not allowed to perform this action': "Vous n'êtes pas autorisé à effectuer cette action",
    'Bad request': 'Mauvaise Requête',
    'Internal server error': 'Erreur interne du serveur',
    'The server is not responding, please try again later': 'Le serveur ne répond pas, veuillez réessayer plus tard',
    'The service was successfully saved': 'Le service a été sauvegardé avec succès',
    'The service was successfully deleted': 'Le service a été supprimé avec succès',
    'The trainer was successfully saved': 'Le formateur a été sauvegardé avec succès',
    'The trainer was successfully deleted': 'Le formateur a été supprimé avec succès',
    'The user was successfully saved': "L'utilisateur a été enregistré avec succès",
    'The user was successfully deleted': "L'utilisateur a été supprimé avec succès",
    General: 'Général',
    Profile: 'Profil',
    Organization: 'Organisation',
    Contact: 'Contact chargé de direction',
    Address: 'Adresse',
    Credits: 'Crédits',
    creditType: {
        foco: 'FoCo',
        accpro: 'AccPro',
    },
    validations: {
        required: 'Ce champ est requis',
        email: "Le courriel n'est pas valide",
    },
    Certificate: 'Certificat',
    'Add certificate': 'Ajouter un certificat',
    'Remove certificate': 'Supprimer un certificat',
    'Certificate Type': 'Type de certificat',
    Specification: 'Spécification',
    'Activity Type': "Type d'activité",
    'Processing Date': 'Date de traitement',
    OK: 'OK',
    trainer: {
        title: 'Formateur|Formateurs',
    },
    Status: 'Statut',
    Employer: 'Employeur',
    'Is State Employee': "Est employé·e de l'État",
    Languages: 'Langues',
    Diplomas: 'Diplômes',
    Certificates: 'Certificats',
    'n/a': 'n.d.',
    'Delete trainer': 'Supprimer le formateur',
    'Are you sure you want to delete this trainer?': 'Êtes-vous sûr de vouloir supprimer ce document ?',
    Budget: 'Budget',
    roles: {
        [Roles.SNJ]: 'SNJ',
        [Roles.AGENCY]: 'Agence',
        [Roles.MANAGER]: 'Gestionnaire',
        [Roles.SERVICE]: 'Service',
    },
    domains: {
        [Domains.PEDAGOGIC]: 'Orientation pédagogique',
        [Domains.CHILDDEVELOPMENT]: "Développement de l'enfant et du jeune",
        [Domains.APPROACHEMETHODOLOGIESANDPEDAGOGIC]: 'Approches et méthodologies pédagogiques',
        [Domains.EMOTIONSOCIAL]: 'Emotions et relations sociales',
        [Domains.VALUEPARTICIPATIONDEMOCRACY]: 'Valeurs, participation et démocratie',
        [Domains.LANGUAGECOMMUNICATIONMEDIAS]: 'Langue, communication et médias',
        [Domains.AESTHETICCREATIVITYART]: 'Esthétique, créativité et art',
        [Domains.MOVEMENTCONSCIOUSNESSHEALTH]: 'Mouvement, Conscience corporelle et santé',
        [Domains.SCIENCETECHNOLOGY]: 'Sciences et technique',
        [Domains.TRANSITIONS]: 'Transitions',
        [Domains.MANAGERIALSKILLS]: 'Management et cadre réglementaire - Compétences managériales',
        [Domains.JURISDICTIONOVERLEGISLATION]: 'Management et cadre réglementaire - Compétences en matière de législation',
        [Domains.OCCUPATIONALHEALTHSAFETY]: 'Sécurité et santé au travail',
        [Domains.COACHING]: 'Coaching',
        [Domains.SUPERVISION]: 'Supervision',
    },
    LuxTrustSN20: 'Le numéro SSN de LuxTrust',
    userId: 'Identifiant CGIE IAM',
    Logout: 'Déconnexion',
    Export: 'Exporter',
};
