import λ from '~/utils/invoker';
const authorizationλ = λ.namespaced('authorization');
export default {
    computed: {
        authorization: authorizationλ.getter('authorization'),
        canListUsers() {
            return this.authorizedTo('listUsers');
        },
        canReadUsers() {
            return this.authorizedTo('readUsers');
        },
        canCreateUsers() {
            return this.authorizedTo('createUsers');
        },
        canUpdateUsers() {
            return this.authorizedTo('updateUsers');
        },
        canUpdateOwnUser() {
            return function canUpdateOwnUser(id) {
                return this.authorizedTo('updateOwnUser', id);
            };
        },
        canChangeUserRole() {
            return this.authorizedTo('changeUserRole');
        },
        canChangeUserOrganization() {
            return this.authorizedTo('changeUserOrganization');
        },
        canDeleteUsers() {
            return this.authorizedTo('deleteUsers');
        },
        canListServices() {
            return this.authorizedTo('listServices');
        },
        canReadServices() {
            return this.authorizedTo('readServices');
        },
        canReadOwnService() {
            return function canReadOwnService(id) {
                return this.authorizedTo('readOwnService', id);
            };
        },
        canCreateServices() {
            return this.authorizedTo('createServices');
        },
        canUpdateServices() {
            return this.authorizedTo('updateServices');
        },
        canUpdateOwnService() {
            return function canUpdateOwnService(id) {
                return this.authorizedTo('updateOwnService', id);
            };
        },
        canDeleteServices() {
            return this.authorizedTo('deleteServices');
        },
        canExportServices() {
            return this.authorizedTo('exportServices');
        },
        canListAgencies() {
            return this.authorizedTo('listAgencies');
        },
        canReadAgencies() {
            return this.authorizedTo('readAgencies');
        },
        canReadOwnAgency() {
            return function canReadOwnAgency(id) {
                return this.authorizedTo('readOwnAgency', id);
            };
        },
        canCreateAgencies() {
            return this.authorizedTo('createAgencies');
        },
        canUpdateAgencies() {
            return this.authorizedTo('updateAgencies');
        },
        canUpdateOwnAgency() {
            return function canUpdateOwnAgency(id) {
                return this.authorizedTo('updateOwnAgency', id);
            };
        },
        canDeleteAgencies() {
            return this.authorizedTo('deleteAgencies');
        },
        canExportAgencies() {
            return this.authorizedTo('exportAgencies');
        },
        canListTrainings() {
            return this.authorizedTo('listTrainings');
        },
        canReadTrainings() {
            return this.authorizedTo('readTrainings');
        },
        canCreateTrainings() {
            return this.authorizedTo('createTrainings');
        },
        canUpdateTrainings() {
            return this.authorizedTo('updateTrainings');
        },
        canDeleteTrainings() {
            return this.authorizedTo('deleteTrainings');
        },
        canExportTrainings() {
            return this.authorizedTo('exportTrainings');
        },
        canListTrainers() {
            return this.authorizedTo('listTrainers');
        },
        canReadTrainers() {
            return this.authorizedTo('readTrainers');
        },
        canCreateTrainers() {
            return this.authorizedTo('createTrainers');
        },
        canUpdateTrainers() {
            return this.authorizedTo('updateTrainers');
        },
        canDeleteTrainers() {
            return this.authorizedTo('deleteTrainers');
        },
        canExportTrainers() {
            return this.authorizedTo('exportTrainers');
        },
        canListParticipations() {
            return this.authorizedTo('listParticipations');
        },
        canListOwnParticipations() {
            return this.authorizedTo('listOwnParticipations');
        },
        canReadParticipations() {
            return this.authorizedTo('readParticipations');
        },
        canCreateParticipations() {
            return this.authorizedTo('createParticipations');
        },
        canUpdateParticipations() {
            return this.authorizedTo('updateParticipations');
        },
        canDeleteParticipations() {
            return this.authorizedTo('deleteParticipations');
        },
        canExportParticipations() {
            return this.authorizedTo('exportParticipations');
        },
    },
    methods: {
        authorizedTo(permission, target) {
            return this.authorization.can(permission, target);
        },
        mayBeAuthorizedTo(permission) {
            return this.authorization.has(permission);
        },
        authorizedToEither(permissions, target) {
            return this.authorization.any(permissions, target);
        },
        authorizedToAll(permissions, target) {
            return this.authorization.all(permissions, target);
        },
    },
};
