import λ from '~/utils/invoker';
import { Optional } from 'lonad';
import { pipe, prop } from 'ramda';
import { displayName } from '#/user';
const authorizationλ = λ.namespaced('authorization');
export const authenticated = authorizationλ.getter('authenticated');
export const currentUserName = pipe(authorizationλ.getter('user'), Optional.map(displayName), Optional.getOrElse(''));
export const currentUserRole = pipe(authorizationλ.getter('user'), Optional.property('role'), Optional.getOrElse(''));
export const currentUserId = pipe(authorizationλ.getter('user'), Optional.map(prop('_id')));
export const currentUser = authorizationλ.getter('user');
