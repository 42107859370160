import { CreditType } from '#/credit';
import { TrainingType } from '#/training-type';
export function createTraining(values = {}) {
    return Object.assign({}, {
        reference: null,
        name: null,
        domain: null,
        description: null,
        hours: 0,
        creditType: CreditType.FOCO,
        type: TrainingType.STANDARD,
        organizer: null
    }, values);
}
