import '~/registerServiceWorker';
import Vue from 'vue';
import App from '~/App.vue';
import i18n from '~/i18n/translator';
import router from '~/router';
import store from '~/store';
import vuetify from '~/plugins/vuetify';
import clonedeep from 'lodash.clonedeep';
import configuration from '~/client-configuration';
import { setDefaultLocale } from '#/date-fns-locales';
import GlobalConfirmDialog from '~/plugins/global-confirm-dialog-plugin';
import './input-validator';
import { always } from 'ramda';
Vue.config.productionTip = false;
Vue.use(GlobalConfirmDialog);
setDefaultLocale(configuration.locale);
const initialState = clonedeep(store.state);
store
    .dispatch('authorization/refresh')
    .then(() => {
    new Vue({
        i18n,
        router,
        store,
        vuetify,
        data: always({ initialState }),
        render: (h) => h(App),
    }).$mount('#app');
});
