import { createSession } from '#/session';
export function createParticipation(values = {}) {
    return Object.assign({}, {
        service: null,
        session: createSession(values === null || values === void 0 ? void 0 : values.session),
        present: 0,
        excused: 0,
        absent: 0,
        credits: 0,
        notes: '',
    }, values);
}
export function evaluateTotalParticipants(participation) {
    return participation.present + participation.absent;
}
export function evaluateUsedCredits(participation, training) {
    return training.hours * evaluateTotalParticipants(participation);
}
