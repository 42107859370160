import { createAllCredits } from './credit';
export function createAddress(values = {}) {
    return Object.assign({}, {
        number: undefined,
        street: '',
        zip: '',
        city: '',
    }, values);
}
export function createContact(values = {}) {
    return Object.assign({}, {
        firstName: '',
        lastName: '',
        email: '',
    }, values);
}
export function createAgency(values = {}) {
    return Object.assign({}, {
        name: '',
        address: createAddress(values === null || values === void 0 ? void 0 : values.address),
        contactAccreditation: createContact(values === null || values === void 0 ? void 0 : values.contact),
        contactTrainings: createContact(values === null || values === void 0 ? void 0 : values.contact),
        credits: createAllCredits(),
    }, values);
}
