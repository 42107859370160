import Authorization from '#/permissions/authorization';
import { serverURL } from '~/backend';
import { Optional } from 'lonad';
import parachute from '~/utils/parachute';
import Session from '~/authorization/session';
import Axios from 'axios';
import { prop, pipe } from 'ramda';
export function initialState() {
    return {
        user: Session.getUser(),
    };
}
const axios = Axios.create({
    baseURL: serverURL,
});
export default {
    namespaced: true,
    state: initialState(),
    actions: {
        logout: parachute(async function logout({ commit, dispatch }) {
            const logoutPageUrl = await dispatch('logoutPageUrl');
            console.log(`%c[authorization-store:logout] %credirecting to logout URL: ${logoutPageUrl}`, 'color: turquoise;', 'color: gray;');
            await Session.logout(logoutPageUrl);
            commit('setUser', Optional.None());
        }),
        refresh: parachute(async function refresh({ commit }) {
            await Session.refresh();
            commit('setUser', Session.getUser());
        }),
        logoutPageUrl: parachute(async function logoutPageUrl({ getters }) {
            return (await axios.get(`/saml/logoutUrl/${getters.nameId}`)).data;
        }),
        loginPageUrl: parachute(async function loginPageUrl({ state }) {
            return (await axios.get('/saml/loginUrl')).data;
        }),
    },
    getters: {
        user: prop('user'),
        nameId: pipe(prop('user'), Optional.property('nameId'), Optional.getOrElse('')),
        userOrganization: pipe(prop('user'), Optional.property('organization'), Optional.getOrElse(null)),
        userOrganizationType: pipe(prop('user'), Optional.property('organizationType'), Optional.getOrElse(null)),
        authenticated: state => prop('user', state).valuePresent,
        authorization(state) {
            return state.user
                .map(user => {
                return Authorization.for(Optional.Some(user), user.permissions);
            })
                .getOrElse(Authorization.always(false));
        },
        authorizedTo(state, getters) {
            return function authorizedTo(permission, target) {
                return getters.authorization.can(permission, target);
            };
        },
    },
    mutations: {
        setUser: (state, user) => {
            if (!user.isOptionalInstance) {
                console.error('AUTHORIZATION STORE: cannot set non-optional user!');
                return;
            }
            state.user = user;
        },
    }
};
