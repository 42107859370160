import { getId } from '#/object-id';
import { createTrainer } from '#/trainer';
import { refreshAll, refreshEdit, cancelEdit, removeOne } from '~/store/mutations';
import { downloadExport } from '~/store/actions';
import { allById } from '~/store/getters';
import { crudParachute, fetchAll, fetchOne, save, remove } from '~/utils/crud-functions';
const TrainerStore = {
    namespaced: true,
    state: initialState(),
    actions: {
        downloadExport,
        fetchAll: crudParachute(async ({ commit, state }, parameters) => {
            const results = await fetchAll(state.name, parameters);
            commit('refreshAll', results);
        }),
        fetchEdit: crudParachute(async ({ commit, state }, objectOrId) => {
            const trainer = await fetchOne(state.name, objectOrId);
            commit('refreshEdit', trainer);
        }),
        saveEdit: crudParachute(async ({ commit, state, dispatch }) => {
            const trainer = await save(state.name, state.editing);
            commit('refreshEdit', trainer);
            dispatch('notifications/show', { message: 'The trainer was successfully saved', color: 'success' }, { root: true });
            return trainer;
        }),
        remove: crudParachute(async ({ commit, state, dispatch }, trainer) => {
            await remove(state.name, getId(trainer));
            commit('removeOne', trainer);
            dispatch('notifications/show', { message: 'The trainer was successfully deleted', color: 'success' }, { root: true });
        }),
        createNew() {
            return createTrainer();
        },
    },
    mutations: {
        refreshAll,
        refreshEdit,
        cancelEdit,
        removeOne,
    },
    getters: {
        allById,
    },
};
export default TrainerStore;
export function initialState() {
    return {
        name: 'trainers',
        all: [],
        editing: undefined,
        totalCount: 0,
    };
}
