import axios from '~/backend';
import * as FileSaver from 'file-saver';
import { crudParachute } from '~/utils/crud-functions';
export const downloadExport = crudParachute(async ({ commit, state }) => {
    var _a, _b;
    const response = await axios.get(`/${state.name}/getExport`, { responseType: 'blob' });
    const filename = (_b = (_a = /filename="(?<filename>.*)"/.exec(response.headers['content-disposition'])) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.filename;
    const mimetype = response.headers['content-type'];
    const blob = new Blob([response.data], { type: mimetype });
    return Promise.resolve(FileSaver.saveAs(blob, filename));
});
