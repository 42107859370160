import { ObjectId as ObjectID } from 'bson/lib/objectid';
export function getId(idOrEntity) {
    if (!idOrEntity) {
        throw new Error('Cannot getId() on a falsy');
    }
    if (idOrEntity._bsontype) {
        return idOrEntity.toString();
    }
    if (typeof idOrEntity === 'string') {
        return idOrEntity;
    }
    if (!idOrEntity._id) {
        return null;
    }
    return idOrEntity._id.toString();
}
export function isValidObjectId(input) {
    if (!input) {
        return false;
    }
    const asString = input.toString();
    return Boolean(asString.match(/^[0-9a-fA-F]{24}$/));
}
export function ObjectId(id) {
    return new ObjectID(id);
}
