import { required, email, integer, min_value as minValue } from 'vee-validate/dist/rules';
import i18n from '~/i18n/translator';
import { extend } from 'vee-validate';
extend('required', {
    ...required,
    message: (_, values) => i18n.t('validations.required', values),
});
extend('email', {
    ...email,
    message: (_, values) => i18n.t('validations.email', values),
});
extend('integer', {
    ...integer,
    message: (_, values) => i18n.t('validations.integer', values),
});
extend('min_value', {
    ...minValue,
    message: (_, values) => i18n.t('validations.min_value', values),
});
