import ConfirmDialog from '~/components/confirm-dialog.vue';
import GlobalConfirmDialog from '~/plugins/global-confirm-dialog-plugin';
export default {
    data() {
        return {
            currentProps: {},
        };
    },
    components: {
        ConfirmDialog,
    },
    beforeMount() {
        GlobalConfirmDialog.EventBus.$on('open', (props, resolve, reject) => {
            this.currentProps = props || {};
            return this.$refs.globalConfirmDialog.open()
                .then(resolve)
                .catch(reject);
        });
    },
};
