import { ObjectId } from '#/object-id';
import upperFirst from 'lodash.upperfirst';
export var Language;
(function (Language) {
    Language["FRENCH"] = "fr";
    Language["ENGLISH"] = "en";
    Language["GERMAN"] = "de";
    Language["LUXEMBOURGISH"] = "lb";
    Language["SPANISH"] = "ES";
    Language["PORTUGUESE"] = "PT";
})(Language || (Language = {}));
export function createCertificate(values = {}) {
    return Object.assign({}, {
        _id: String(ObjectId()),
        type: '',
        activityType: '',
        specification: '',
        processingDate: undefined,
        agency: null,
    }, values);
}
export function createTrainer(values = {}) {
    var _a;
    return Object.assign({}, {
        firstName: '',
        lastName: '',
        email: '',
        employer: '',
        isStateEmployee: false,
        languages: '',
        diploma: '',
        status: '',
        certificates: ((_a = values === null || values === void 0 ? void 0 : values.certificates) !== null && _a !== void 0 ? _a : []).map(createCertificate),
    }, values);
}
export function displayName(trainer) {
    return ((trainer === null || trainer === void 0 ? void 0 : trainer.lastName) && (trainer === null || trainer === void 0 ? void 0 : trainer.firstName)
        ? `${trainer.lastName.toUpperCase()} ${upperFirst(trainer.firstName)}`
        : '');
}
