import { getId } from '#/object-id';
import { stringEnumValues } from '#/enum';
import { cond, always, equals, T } from 'ramda';
export var OrganizationTypes;
(function (OrganizationTypes) {
    OrganizationTypes["AGENCY"] = "agencies";
    OrganizationTypes["SERVICE"] = "services";
})(OrganizationTypes || (OrganizationTypes = {}));
export const depluralize = cond([
    [equals(OrganizationTypes.AGENCY), always('agency')],
    [equals(OrganizationTypes.SERVICE), always('service')],
    [T, always(null)]
]);
export const organizationTypes = stringEnumValues(OrganizationTypes);
export function getUserOrganizationOverride(organizationType, organization) {
    return {
        [depluralize(organizationType)]: getId(organization),
    };
}
