import { allPass } from 'ramda';
class Permission {
    constructor({ name, conditions = [] } = {}) {
        this.name = name;
        this.conditions = conditions;
    }
    static allow(name) {
        return new Permission({ name });
    }
    when(lamda) {
        if (Array.isArray(lamda)) {
            lamda.forEach(this.when.bind(this));
            return this;
        }
        if (typeof lamda !== 'function'
            || typeof lamda.name !== 'string'
            || !lamda.name.trim()) {
            throw new Error('Conditions must be named functions!');
        }
        this.conditions.push(lamda);
        return this;
    }
    test(user, target) {
        try {
            return allPass(this.conditions)({ user, target });
        }
        catch (e) {
            return false;
        }
    }
    toString() {
        return this.name;
    }
}
export default Permission;
