import * as λ from 'callcise-vuex';
import { mdiClose } from '@mdi/js';
const notificationsλ = λ.namespaced('notifications');
export default {
    data() {
        return {
            mdiClose,
        };
    },
    computed: {
        notifications: notificationsλ.state('notifications'),
    },
    methods: {
        discard: notificationsλ.mutation('discard'),
        clear(notification) {
            notification.show = false;
            this.discard();
        },
    },
};
