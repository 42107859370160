import { getId } from '#/object-id';
import { createUser } from '#/user';
import { refreshAll, refreshEdit, cancelEdit, removeOne } from '~/store/mutations';
import { allById } from '~/store/getters';
import { crudParachute, fetchAll, fetchOne, save, remove } from '~/utils/crud-functions';
const UserStore = {
    namespaced: true,
    state: initialState(),
    actions: {
        fetchAll: crudParachute(async ({ commit, state }, parameters) => {
            const results = await fetchAll(state.name, parameters);
            commit('refreshAll', results);
        }),
        fetchEdit: crudParachute(async ({ commit, state }, objectOrId) => {
            const user = await fetchOne(state.name, objectOrId);
            commit('refreshEdit', user);
        }),
        saveEdit: crudParachute(async ({ commit, state, dispatch }) => {
            const user = await save(state.name, state.editing);
            commit('refreshEdit', user);
            dispatch('notifications/show', { message: 'The user was successfully saved', color: 'success' }, { root: true });
            return user;
        }),
        remove: crudParachute(async ({ commit, state, dispatch }, user) => {
            await remove(state.name, getId(user));
            commit('removeOne', user);
            dispatch('notifications/show', { message: 'The user was successfully deleted', color: 'success' }, { root: true });
        }),
        createNew() {
            return createUser();
        },
    },
    mutations: {
        refreshAll,
        refreshEdit,
        cancelEdit,
        removeOne,
    },
    getters: {
        allById,
    },
};
export default UserStore;
export function initialState() {
    return {
        name: 'users',
        all: [],
        editing: undefined,
        totalCount: 0,
    };
}
